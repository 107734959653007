/*
 * @description: Don't description
 * @Author: Mustafa.Yin
 * @Date: 2022-05-15 19:22:54
 * @FilePath: \smart-reco-xnd:\vue_project\smart-reco-h5\src\main.js
 * @LastEditTime: 2025-01-05 21:17:15
 */
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/tailwind.css'
import loadingViewerVue from 'loading-viewer-vue'
import { Dialog } from 'vant'
import { Icon } from 'vant'
import { BonreeStart, BonreeVUE } from './utils/BonreeSDK_JS.esm.js'
createApp(App).use(router).use(store).use(Vant).use(loadingViewerVue,{mode:'2'}).use(Dialog).use(Icon).mount('#app')
BonreeStart({
    "reqHeaderTraceKey": [
        "tracestate",
        "traceparent"
    ],
    "uploadAddrHttps": "https://oneupload.bonree.com/RUM/upload",
    "mc": [
        {
            "n": "network",
            "cs": {
                "fc": 0
            }
        }
    ],
    "appId": "c68db396022e4780a81b21828db4f3b3",
    "uploadAddrHttp": "http://oneupload.bonree.com/RUM/upload",
    "respHeaderTraceKey": [
        "traceresponse",
        "x-br-response"
    ],
    "brss": false
})
// BonreeVUE(createApp)

Date.prototype.Format = function (fmt) {
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        'S': this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
    return fmt
}