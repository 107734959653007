<!--
 * @description: Don't description
 * @Author: Mustafa.Yin
 * @Date: 2022-05-15 19:22:54
 * @FilePath: \reco-uif:\source code\EVET\smart-reco-h5\src\components\PageHeader.vue
 * @LastEditTime: 2022-07-25 02:21:59
-->
<template>
<div class="bg-white dark:bg-white">
  <van-row>
    <van-col span="24">
      <div style="background-color: white;"></div>
    </van-col>
  </van-row>
  <van-row>
    <van-col offset="5" span="6" style="text-align: right;">
      <router-link to="/home?shopId=2327299222177991915&personId=9113221422322173813&companyId=676344787275836663414260">
<!--        <van-image-->
<!--            width="100%;"-->
<!--            height="100%"-->
<!--            :src="logoPng"-->
<!--        />-->
        <img src="../assets/logo.png">
      </router-link>
    </van-col>
    <van-col span="2" style="text-align: center;">
      <div style="display: inline-block; border-right: 2px solid black; width: 1px;height: 100%;"></div>
    </van-col>
    <van-col span="6" style="text-align: left;text-align: center;">
      <img src="../assets/wlogo.png">
    </van-col>
  </van-row>
  <van-divider></van-divider>
  </div>
</template>


<script>
import logoPng from '../assets/logo.png';
export default {
  name: "PageHeader",
  setup(){
    return {
      logoPng,
    }
  }
}
</script>

<style scoped>

</style>
